import axios from '@/utils/axios'

// 追责/预警 回访
export const returnReturnVistT = data =>
  axios.post('/crm/customer/returnReturnVistT', data)
    .then(res => res.data)

// 添加 追责/预警 回访
export const addReturnVistT = data =>
  axios.post('/crm/customer/addReturnVistT', data)
    .then(res => res)

// 追责/预警 详情
export const readReturnVistT = data =>
  axios.post('/crm/customer/readReturnVistT', data)
    .then(res => res.data)

//追责/预警 回访 添加跟进
export const addReturnVistTLog = data =>
  axios.post('/crm/customer/addReturnVistTLog', data)
    .then(res => res)

//追责/预警 处理
export const saveReturnVistT = data =>
  axios.post('/crm/customer/saveReturnVistT', data)
    .then(res => res)

//追责/预警 导出
export const exportReturn = data =>
  axios.post('/crm/customer/returnReturnVistT_export', data)
    .then(res => res)
