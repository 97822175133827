<template>
  <div >
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
           <el-form-item label="优惠券类型">
            <el-select v-model="search.type" placeholder="请选优惠券类型" class="w180" clearable>
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="优惠券名称" prop="name" />
            <ElTableColumn label="面值(元)" prop="denomination"  width="150"/>
            <ElTableColumn label="优惠券类型" prop="type">
              <template slot-scope="{ row }">
                <span v-if="row.type == 1">满减券</span>
                <span v-if="row.type == 3">满赠券</span>
                <span v-if="row.type == 4">兑换券</span>
              </template>
            </ElTableColumn>
           <ElTableColumn label="优惠券状态" >
              <template slot-scope="{ row }">
                <span v-if="row.staus == 0">未使用</span>
                <span v-if="row.staus == 1">已使用</span>
                <span v-if="row.staus == 2">已过期</span>
                <span v-if="row.staus == 3">未开始</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="有效期" width="280" >
              <template slot-scope="{ row }">
                <div >{{ row.start_time }} 至 {{ row.end_time }}</div>
              </template>
            </ElTableColumn>
            <ElTableColumn label="领取时间" prop="create_time" />
            <ElTableColumn label="使用时间" prop="use_time" />
            <ElTableColumn label="使用门槛(元)"  prop="use_threshold" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span v-if="row.type == 4 && row.staus == 0" class="theme" @click="tapBtn(row)">兑 换</span>
              </template>
            </ElTableColumn>
        
          </el-table>
        
        </el-col>
      </el-row>
  </div>
</template>

<script>
import { getCouponProvide } from '@/api/client';
class Search {
  type = "";
}
class Options {
  type = [
    { id: 1, name: "满减券" },
    { id: 3, name: "满赠券" },
    { id: 4, name: "兑换券" }
  ];
}
export default {
  name: 'CardList',
   props: {
    clienId: {
      type: String,
      default: ""
    },
     detail: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      list: [], // 数据展示
    }
  },

  activated () {},
  mounted () {
    this.getList()
  },
  methods: {
    // 获取列表
    getList () {
      getCouponProvide({...this.search,customer_id:this.clienId}).then(res => {
        this.list = res.data 
      })
    },
    tapBtn(row){
      const query = { clienId: this.clienId, detail: this.detail,yhj_id:row.id };
      this.$router.push({ name: "兑换订单", query });
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 30px 0 50px; 
}
</style>
