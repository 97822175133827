 <template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="成交日期">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w220"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="客户名称" v-if="!clienId">
            <el-input
              v-model="search.consignee"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="门店名称" v-if="!clienId">
            <el-input
              v-model="search.shop_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="退款金额">
            <el-input
              v-model="search.low_total"
              maxlength="30"
              class="w84"
              placeholder="最小值"
              clearable
            />
            <span></span>
            <el-input
              v-model="search.large_total"
              maxlength="30"
              class="w84"
              placeholder="最大值"
              clearable
            />
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="search.business_type" placeholder="请选业务类型" class="w120" clearable>
              <el-option
                v-for="item in options.business_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="search.order_type" placeholder="请选订单类型" class="w120" clearable>
              <el-option
                v-for="item in options.order_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="作废订单">
            <el-select v-model="search.display_cannel" placeholder="是否显示作废订单" class="w120" clearable>
              <el-option
                v-for="item in options.display_cannel"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          type="primary"
          v-if="!userInfo.shop_id"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="tapBtn('export')"
        >导出数据</el-button>
        <div v-if="userInfo.shop_id"></div>
        <el-button
          v-if="!clienId"
          type="primary"
          class="mb10"
          size="mini"
          @click="tapBtn('lock')"
        >锁定订单</el-button>
        <span v-if="is_show_add">
          <el-button
          type="primary"
          v-if="clienId"
          round
          class="mb10"
          size="mini"
          @click="tapBtn('goods')"
        >添加退货订单</el-button>
        </span>
       
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="成交日期" prop="deal_time" />
            <ElTableColumn label="客户名称" prop="consignee" />
            <ElTableColumn label="客户K3代码" prop="king_no" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="护理师" prop="sale_name" />
            <ElTableColumn label="标准金额" prop="goods_price" />
            <ElTableColumn label="退货金额" prop="total_amount" />
            <ElTableColumn label="业务类型" prop="business_type" />
            <ElTableColumn label="订单类型" prop="order_type" />
            <ElTableColumn label="订单状态" prop="status_msg" />
            <ElTableColumn label="是否锁定">
              <template slot-scope="{ row }">
                <span>{{row.is_lock == 0?'未锁定':'已锁定'}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" v-if="clienId" @click="tapBtn('客户退货详情',row)">查 看</span>
                <span class="theme" v-else @click="tapBtn('退货详情',row)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <div class="reveal" v-if="sum_amount != 'noShow'">
            <span class="mr20">
              退货金额合计：
              <span class="peril">{{sum_amount}}</span>
            </span>
          </div>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { refundList, refundExport, lockOrderR } from "@/api/order";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  consignee = "";
  shop_name = "";
  low_total = "";
  large_total = "";
  business_type = "";
  order_type = "";
  display_cannel = 0;
}
class Options {
  business_type = [
    { id: 1, name: "疤痕" },
    { id: 2, name: "项目" }
  ];
  order_type = [
    { id: 1, name: "新单" },
    { id: 2, name: "补单" }
  ];
  display_cannel = [
    { id: 0, name: "不显示" },
    { id: 1, name: "显示" }
  ];
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: "",
      value1: [],
      sum_amount: "noShow",
      is_show_add:true
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option == "search") this.page = new Page();
      refundList({
        ...this.search,
        ...this.page,
        type: 2,
        uid: this.clienId ? this.clienId : "",
        status: "-1"
      }).then(res => {
        this.is_show_add = res.is_show_add
        this.list = res.list;
        this.page.total = res.dataCount;
        if (this.search.end_time) {
          this.sum_amount = res.sum_amount;
        } else {
          this.sum_amount = "noShow";
        }
      });
    },
    // 日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.end_time = val[1];
      } else {
        this.search.start_time = "";
        this.search.end_time = "";
      }
    },
    // 点击详情
    tapBtn(type, row) {
      switch (type) {
        case "退货详情":
          this.$router.push(
            "./refundDetail?id=" + row.id + "&type=" + row.type
          );
          break;
        case "客户退货详情":
          const queryT = {
            id: row.id,
            types: "fifth",
            clienId: this.clienId,
            type: row.type,
            detail: this.detail
          };
          this.$router.push({ name: "退/收单详情", query: queryT });
          break;
        case "goods":
          const querys = { detail: this.detail };
          this.$router.push({ name: "添加退货订单", query: querys });
          break;
        case "export":
          this.hint("导出选中列表", "export");
          break;
        case "lock":
          if (this.search.end_time) {
            this.hint("锁定选中条件的订单", "lock");
          } else {
            this.$message({
              message: "请选择锁定日期",
              type: "warning"
            });
          }
          break;
      }
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "export") {
            this.getExport();
          } else {
            this.getLockOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //锁单
    getLockOrder() {
      lockOrderR({ ...this.search, type: 2 }).then(res => {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getList();
      });
    },
    // 导出
    getExport() {
      refundExport({ id: this.idList, type: 2 }).then(res => {
        window.open(res.data.url);
      });
    },

    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
</style>
