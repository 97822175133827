var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticClass:"bgW"},[_c('el-col',{attrs:{"span":22}},[_c('el-form',{staticClass:"search",attrs:{"inline":true,"label-suffix":"：","size":"mini","model":_vm.search}},[_c('el-form-item',{attrs:{"label":"优惠券类型"}},[_c('el-select',{staticClass:"w180",attrs:{"placeholder":"请选优惠券类型","clearable":""},model:{value:(_vm.search.type),callback:function ($$v) {_vm.$set(_vm.search, "type", $$v)},expression:"search.type"}},_vm._l((_vm.options.type),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":2}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","size":"mini"},on:{"click":function($event){return _vm.getList('search')}}},[_vm._v("搜索")])],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('el-table',{attrs:{"data":_vm.list,"size":"small","border":"","cell-style":_vm.$style.cellStyle,"header-cell-style":_vm.$style.rowClass}},[_c('ElTableColumn',{attrs:{"label":"优惠券名称","prop":"name"}}),_c('ElTableColumn',{attrs:{"label":"面值(元)","prop":"denomination","width":"150"}}),_c('ElTableColumn',{attrs:{"label":"优惠券类型","prop":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 1)?_c('span',[_vm._v("满减券")]):_vm._e(),(row.type == 3)?_c('span',[_vm._v("满赠券")]):_vm._e(),(row.type == 4)?_c('span',[_vm._v("兑换券")]):_vm._e()]}}])}),_c('ElTableColumn',{attrs:{"label":"优惠券状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.staus == 0)?_c('span',[_vm._v("未使用")]):_vm._e(),(row.staus == 1)?_c('span',[_vm._v("已使用")]):_vm._e(),(row.staus == 2)?_c('span',[_vm._v("已过期")]):_vm._e(),(row.staus == 3)?_c('span',[_vm._v("未开始")]):_vm._e()]}}])}),_c('ElTableColumn',{attrs:{"label":"有效期","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.start_time)+" 至 "+_vm._s(row.end_time))])]}}])}),_c('ElTableColumn',{attrs:{"label":"领取时间","prop":"create_time"}}),_c('ElTableColumn',{attrs:{"label":"使用时间","prop":"use_time"}}),_c('ElTableColumn',{attrs:{"label":"使用门槛(元)","prop":"use_threshold"}}),_c('ElTableColumn',{attrs:{"label":"操作","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type == 4 && row.staus == 0)?_c('span',{staticClass:"theme",on:{"click":function($event){return _vm.tapBtn(row)}}},[_vm._v("兑 换")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }