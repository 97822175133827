<template>
  <div class="content">

    <div class="accountCon">
      <div class="btnBox">
        <div v-if="clienId"></div>

        <el-button type="primary" v-if="notHave" class="mb10" size="mini" round
          @click="tapBtn('治疗方案','')">修改方案</el-button>
          <el-button type="primary" v-else class="mb10" size="mini" round
          @click="tapBtn('治疗方案','')">添加治疗</el-button>
      </div>
      
      
      <div  v-if="notHave" class="resume-detail">
        <el-card>
          <div>
            <el-descriptions :column="3" border class="resume-label-width">
              <el-descriptions-item label="客户名称" :span="1">{{ detail.customer_name }}</el-descriptions-item>
              <el-descriptions-item label="性别" :span="1">{{ detail.customer_sex }}</el-descriptions-item>
              <el-descriptions-item label="生日" :span="1">{{ detail.customer_birthday }}</el-descriptions-item>

              <el-descriptions-item label="病历号" :span="1">240418003</el-descriptions-item>
              <el-descriptions-item label="就诊日期" :span="1">{{ detail.heal_date }}</el-descriptions-item>
              <el-descriptions-item label="主诊医生" :span="1">{{ detail.doctor }}</el-descriptions-item>

              <el-descriptions-item label="主诉" :span="3">{{ detail.text1 }}</el-descriptions-item>
              <el-descriptions-item label="既往过敏史" :span="3">{{ detail.text2 }}</el-descriptions-item>
              <el-descriptions-item label="现病史" :span="3">{{ detail.text3 }}</el-descriptions-item>
              <el-descriptions-item label="既往史" :span="3">{{ detail.text4 }}</el-descriptions-item>
              <el-descriptions-item label="家族史" :span="3">{{ detail.text5 }}</el-descriptions-item>
              <el-descriptions-item label="皮肤检查" :span="3">{{ detail.text6 }}</el-descriptions-item>
              <el-descriptions-item label="辅助检查" :span="3">{{ detail.text7 }}</el-descriptions-item>
              <el-descriptions-item label="诊断" :span="3">{{ detail.text8 }}</el-descriptions-item>

              <el-descriptions-item v-for="item in advice" :label="item.id" :span="3">{{ item.name }}</el-descriptions-item>

              <el-descriptions-item label="处置" :span="3">{{ detail.text10 }}</el-descriptions-item>
              <el-descriptions-item label="下次治疗建议" :span="3">{{ detail.text11 }}</el-descriptions-item>
              <el-descriptions-item label="治疗方案" :span="3">{{ detail.heal_plan }}</el-descriptions-item>
              <el-descriptions-item label="面诊后第1月-3月治疗规划" :span="3">{{ detail.text12 }}</el-descriptions-item>
            </el-descriptions>

            
          </div>
        </el-card>
      </div>

      <div v-else>
        <el-empty description="暂无数据" class="mr50 mt50"></el-empty>
      </div>
    </div>


  </div>
</template>
  
  <script>
  import { mapGetters } from "vuex";
  import pagination from "@/components/Pagination";
  import { getHealPlan } from "@/api/client";
  class Search {
    
  }
  class Detail {
    customer_id = '';
  }
  class Rules {
    
  }
  class Options {
    heal_plan = [
        {id:1,name:'Plasma治疗'},
        {id:2,name:'强脉冲多模式治疗'},
        {id:3,name:'分解色素治疗'},
        {id:4,name:'祛红阻断红血丝'},
        {id:5,name:'皮下剥离增容术'},
        {id:6,name:'疤痕胶原降解'},
        {id:7,name:'Plasma透皮治疗'},
        {id:8,name:'负压导入'},
        {id:9,name:'胶原再生'},
        {id:10,name:'屏障修复'},
        {id:11,name:'黄金微针'},
        {id:12,name:'红蓝光'},
        {id:13,name:'湿性愈合'},
        {id:14,name:'换药'},
    ];
    doctorAdvice = [
        {id:1,name:'治疗后前三天每天换包扎，并传照片观察。三天以后可以根据情况安排用药。'},
        {id:2,name:'换药期间要注意消毒，无菌操作，避免感染。'},
        {id:3,name:'一个月左右来医院复查，安排下次治疗。'},
        {id:4,name:'注意饮食:禁辛辣刺激食物，面部注意补水保湿防晒。'},
    ];
    sex = [
        {id:1,name:"男"},
        {id:2,name:"女"},
    ];
  }
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  export default {
    name: "ClientList",
    components: {
      pagination
    },
    props: {
      clienId: {
        type: String,
        default: ""
      },
      consignee: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        page: new Page(),
        search: new Search(),
        options: new Options(),
        detail: new Detail(),
        rules: new Rules(),
        notHave: false,
        heal_id:'',
        advice:[],
      };
    },
    filters: {
      statusFilter(val) {
        return ["启用", "禁用"][+val];
      }
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    mounted() {
      this.detail.customer_id = this.clienId ? this.clienId : "";
      this.getHealPlan(this.detail.customer_id);
    },
    methods: {
      // 获取客户治疗方案
      getHealPlan(id){
        getHealPlan({ customer_id:id }).then((res) => {
          console.log(res);

          if(res.data){
            this.detail = res.data;
            let heal_plan = this.options.heal_plan;
            heal_plan.forEach(element => {
              if(res.data.heal_plan == element.id){
                this.detail.heal_plan = element.name;
              }
            });

            let doctorAdvice = this.options.doctorAdvice;
            let text9 = res.data.text9;
            let advice = [];
            

            let adkey = 1;
            text9.forEach(element => {
              doctorAdvice.forEach(item => {
                if(element == item.id){
                  let one = [];
                  one['id'] = '医嘱'+adkey;
                  one['name'] = item.name;

                  advice.push(one);
                }
              });
              adkey++;
              
            });

            // console.log(advice);
            this.advice = advice,
            this.notHave = true;
            this.heal_id = res.data.id;
          }
          
        });
      },
      // 跳转按钮
      tapBtn(routerName, row) {
        console.log(routerName);

        const query = {
          custoemr_id: this.detail.customer_id,
          heal_id: this.heal_id
        };
        if(routerName=='治疗方案'){
          this.$router.push({ name: routerName, query });
        }
      },
      
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .mainColor {
    color: #2dc0a3;
  }
  .content {
    height: calc(100% - 56px);
    overflow-y: auto;
  }
  .bgW {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .el-form-item {
    margin: 0px 20px 3px 0;
  }
  .accountCon {
    background: #fff;
    padding: 0px 10px 30px;
    .btnBox {
      display: flex;
      justify-content: space-between;
    }
    .entryBox {
      height: 600px;
      overflow-y: scroll;
      .entryRow1 {
        display: flex;
        .item-circle {
          width: 18px;
          height: 18px;
          z-index: 2;
          border-radius: 9px;
          background-color: white;
          border: 5px solid #a5ecd7;
        }
        .clientName {
          line-height: 30px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .entryRow2 {
        color: #999;
        margin: 10px 0 0 20px;
        display: flex;
        font-size: 14px;
      }
      .entryRow3 {
        color: #444;
        margin: 10px 0 20px;
        font-size: 15px;
      }
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .resume-detail {
    width: 100%;
    margin: 10px auto 30px;
  }
  .resume-detail .resume-title h3 {
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
  }
  .resume-detail ::v-deep .el-descriptions__body .el-descriptions__table {
    width: 960px;
  }
  .resume-label-width ::v-deep .el-descriptions--medium.is-bordered .el-descriptions-item__cell {
    width: 159px;
  }
  .resume-label-width ::v-deep.el-descriptions-item__label.is-bordered-label {
    color: #606266;
  }
  .resume-detail ::v-deep .el-descriptions {
    margin-top: -1px;
  }
  .resume-detail ::v-deep .el-divider--horizontal {
    height: 2px;
    margin: 5px 0;
    background-color: #aaacaf;
  }
  </style>
  