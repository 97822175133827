<template>
  <div>
    <div class="conMsg">
      <div class="top">
        <div class="title">
          <i class="el-icon-collection-tag" />基本资料
        </div>
        <div>
          <el-button
            v-if="detail.turn_status == 1"
            class="mb10"
            type="primary"
            size="mini"
            round
            @click="hint('turnShop','收到该客户转店')"
          >收到转店</el-button>
          <el-button
            v-if="detail.turn_status == 0||detail.turn_status == 2"
            class="mb10"
            type="primary"
            size="mini"
            round
            @click="turnShop"
          >客户转店</el-button>
          <el-button class="mb10" type="primary" size="mini" round @click="openDialog('编辑资料')">编辑资料</el-button>
        </div>
      </div>
      <div class="uderMsg">
        <el-descriptions class="margin-top" :column="3" border>
          <el-descriptions-item>
            <template slot="label">客户名称</template>
            {{detail.customer_name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">手机号码</template>
            {{detail.telephone}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">客户来源</template>
            {{detail.source_name}}
            <span v-if="detail.source_type_id >0">---- {{detail.customer_source_name}}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">归属门店</template>
            {{detail.shop_name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">客户k3代码</template>
            {{detail.king_no}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">治疗进展</template>
            {{detail.progress_name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">疑难级别</template>
            <span v-if="detail.difficulty_level">{{detail.difficulty_level}}级</span>
            <span v-else>暂无</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">客户分类</template>
            {{detail.class_name?detail.class_name:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">会员标签</template>
            {{detail.member_label_name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">护理师</template>
            {{detail.nurse_name?detail.nurse_name:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">介绍人</template>
            {{detail.introducer_name?detail.introducer_name:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">外联人</template>
            {{detail.outreach_name?detail.outreach_name:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">地址</template>
            <span
              v-if="detail.province_name"
            >{{detail.province_name}}{{detail.city_name}}{{detail.region_name}}{{detail.detailed_address}}</span>
            <span v-else>未知</span>
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">客户状态</template>
            <span v-if="detail.status">{{detail.status == 1?"启用":'禁用'}}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">年龄</template>
            <span v-if="detail.age">{{detail.age}} 岁，({{detail.birthday}})</span>
            <span v-else>未知</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">性别</template>
            <span v-if="detail.sex">{{detail.sex==1?'男':'女'}}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">疤痕类型/位置</template>
            <div>{{detail.type_position}}</div>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">创建时间</template>
            {{detail.create_time?detail.create_time:'暂无'}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">首次进店时间</template>
            {{detail.first_time?detail.first_time:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">二次进店时间</template>
            {{detail.second_time?detail.second_time:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">自定义标签</template>
            {{detail.tag?detail.tag:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">备注</template>
            {{detail.remarks?detail.remarks:'暂无'}}
          </el-descriptions-item>


          <el-descriptions-item>
            <template slot="label">转介绍顾客姓名</template>
            {{detail.turnName?detail.turnName:'暂无'}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">转介绍顾客手机号</template>
            {{detail.turnPhone?detail.turnPhone:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">首次到院</template>
            {{detail.reach_first_time?detail.reach_first_time:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">二次到院</template>
            {{detail.reach_second_time?detail.reach_second_time:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">首次线上看诊</template>
            {{detail.consultation_first_time?detail.consultation_first_time:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">二次线上看诊</template>
            {{detail.consultation_second_time?detail.consultation_second_time:'暂无'}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">黄V预约方式</template>
            {{detail.consultation_status?detail.consultation_status:'暂无'}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">是否显示余额</template>
            {{detail.is_show_balance >0?'是':'否'}}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label"></template>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="conMsg">
        <div class="top">
          <div class="title">
            <i class="el-icon-collection-tag" />统计信息
          </div>
        </div>
        <div class="table">
          <!--列表-->
          <el-table
            :data="detail.blance_list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <el-table-column label="余额类型" prop="category_name" />
            <el-table-column label="当前余额" prop="all_money" />
            <el-table-column label="本金余额" prop="money" />
            <el-table-column label="赠金余额" prop="complimentary_money" />
            <el-table-column label="店转余额" prop="shop_transfer_money" />
          </el-table>
        </div>
      </div>

      <div class="conMsg">
        <div class="top">
          <div class="title">
            <i class="el-icon-collection-tag" />护理师变更
          </div>
        </div>
        <div class="table">
          <!--列表-->
          <el-table
            :data="detail.nurse_log"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <el-table-column label="变更日期" prop="time" />
            <el-table-column label="原护理师" prop="old_name" />
            <el-table-column label="变更对象" prop="name" />
          </el-table>
        </div>
      </div>

      <div style="text-align: right;">
        <!-- <el-button class="mt10" size="mini" @click="imgList = true">查看图片</el-button> -->
        <el-button class="mt10" type="primary" size="mini" @click="upImgBtn">上传照片</el-button>
      </div>

      <div class="conMsg">
        <div class="top">
          <div class="title">
            <i class="el-icon-collection-tag" />图片列表
          </div>
        </div>
        <div class="table">
          <!--列表-->

          <div class="demo-image__preview" v-if="imgsList.length">
            <div class="imgListBox" v-for="item in imgsList" :key="item.id">
              <div class="imgLeft">
                <div class="mb10">
                  <span class="c999 fz12">疤痕类型：</span>
                  {{item.scar_type_name}}
                </div>
                <div class="mb10">
                  <span class="c999 fz12">疤痕部位：</span>
                  {{item.more_name}}
                </div>
                <div class="mb10">
                  <span class="c999 fz12">疤痕成因：</span>
                  {{item.cause_of_formation_name}}
                </div>

                <el-button v-if="item.haveBtn == 'null' || item.haveBtn == 4" class="mt10" type="primary" size="mini" @click="addCaseBtn(item)">添加案例</el-button>
                <el-button v-if="item.haveBtn == 1" class="mt10" type="primary" size="mini" @click="checkCase(item.caseId)">查看案例</el-button>

              </div>
              <div class="imgRight">
                <div class="itemImg ml20" v-for="(val, index) in item.list" :key="index">
                  <el-image :src="val.src" :preview-src-list="urlList" @click="clickImg(item,val)"></el-image>
                  <i class="el-icon-circle-close" @click="clickDelImg(val)"></i>
                  <div class="upTime">{{val.create_time}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="notHave el-icon-picture-outline">暂无数据</div>

        </div>
      </div>

      <!-- 提交案例 -->
      <el-dialog title="提交案例" :visible.sync="addCasePop" width="50%">
        <el-form
        ref="addAnli"
        label-position="right"
        label-width="170px"
        :rules="rulesAddAnli"
        :model="addAnli"
        class="formBox"
      >
          <el-form-item label="案例治疗全流程:" prop="return_visit_record">
            <el-input v-model="addAnli.return_visit_record" class="w300" placeholder="请填写案例治疗全流程" type="textarea" />
          </el-form-item>
          <el-form-item label="是否毕业:">
            <el-radio-group v-model="addAnli.is_excellent">
              <el-radio :label="0">已毕业</el-radio>
              <el-radio :label="1">治疗中</el-radio>
              <el-radio :label="2">治疗中断</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="申请类型:">
            <el-radio-group v-model="addAnli.graduation_type">
              <el-radio :label="0">优秀案例</el-radio>
              <el-radio :label="1">满意案例</el-radio>
            </el-radio-group>
          </el-form-item>

          <div class="btnBox">
            <el-button type="primary" size="mini" @click="addCaseForm()">确 定</el-button>
          </div>


         </el-form>
      </el-dialog>

      <!-- 上传图片弹窗 -->
      <el-dialog title="上传图片" :visible.sync="addImg" width="500px">
        <el-form
          ref="imgDetail"
          label-width="100px"
          :rules="rulesImg"
          :model="imgDetail"
          size="mini"
        >
          <el-form-item label="疤痕类型:" prop="scar_type_id">
            <el-select v-model="imgDetail.scar_type_id" placeholder="请选择疤痕类型">
              <el-option
                v-for="item in options.scar_type_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="疤痕部位:" prop="more_id">
            <el-select v-model="imgDetail.more_id" placeholder="请选择疤痕部位">
              <el-option
                v-for="item in options.more_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="疤痕成因:" prop="cause_of_formation_id">
            <el-select v-model="imgDetail.cause_of_formation_id" placeholder="请选择疤痕成因">
              <el-option
                v-for="item in options.cause_of_formation_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="上传图片:">
            <!-- <img-upload :existUrl="existUrl" @setUrl="getUrl" /> -->
            <img-upload
              ref="uploader"
              @setUrl="getImgUrl"
            />
          </el-form-item>
          <div style="text-align: right;">
            <el-button type="primary" size="mini" @click="submitImg('imgDetail')">确 定</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>

    <!-- 转店弹框 -->
    <el-dialog title="客户转店" :visible.sync="dialogVisible" width="40%">
      <el-form
        ref="turnDetail"
        label-position="right"
        label-width="170px"
        :rules="rules"
        :model="turnDetail"
        class="formBox"
      >
        <el-form-item label="转入店铺:" prop="in_shop_id">
          <el-select
            v-model="turnDetail.in_shop_id"
            filterable
            remote
            placeholder="请输入门店名称"
            :remote-method="remoteShop"
            :loading="loading"
            class="w300"
          >
            <el-option
              v-for="item in options.in_shop_id"
              :key="item.shopId"
              :label="item.shopName"
              :value="item.shopId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="转店备注:" prop="reason">
          <el-input v-model="turnDetail.reason" class="w300" placeholder="请填写转店备注" type="textarea" />
        </el-form-item>
        <el-form-item label="是否短信通知:">
          <el-radio-group v-model="turnDetail.is_information">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="btnBox">
          <el-button type="primary" size="mini" @click="submitForm('turnDetail')">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getShopList } from "@/api/shop";
import imgUpload from "@/components/Upload/imgUpload";
import {
  clientDetail,
  setCustomerStatus,
  transferShop,
  ssettingList,
  customerImgAdd,
  customerImgList,
  customerDel,
  customerAddCase
} from "@/api/client";
class TurnDetail {
  in_shop_id = "";
  reason = "";
  is_information = 0;
}
class Rules {
  in_shop_id = [{ required: true, message: "请选择店铺", trigger: "change" }];
  reason = [{ required: true, message: "请填写备注", trigger: "blur" }];
}

// 提交案例------------ start ---------------------
class rulesAddAnli {
  return_visit_record = [{ required: true, message: "请填写案例治疗全流程", trigger: "blur" }];

}

class addAnli {
  addAnliId = "";
  return_visit_record = "";
  is_excellent = 0;
  graduation_type = 0;
}

// 提交案例------------ end ---------------------

class Options {
  in_shop_id = [];
  scar_type_id = [];
  more_id = [];
  cause_of_formation_id = [];
}
class ImgDetail {
  scar_type_id = "";
  more_id = "";
  src = "";
  cause_of_formation_id = "";
}
class RulesImg {
  scar_type_id = [
    { required: true, message: "请选择疤痕类型", trigger: "change" }
  ];
  more_id = [{ required: true, message: "请选择疤痕部位", trigger: "change" }];
  cause_of_formation_id = [
    { required: true, message: "请选择疤痕成因", trigger: "change" }
  ];
}
export default {
  components: {
    imgUpload
  },
  props: {
    clienId: {
      type: String,
      default: ""
    }
  },
  setup(props){
    watch(()=>props.clienId,(newValue,ordValue)=>{
      console.log(newValue);
    });
  },
  data() {
    return {
      turnDetail: new TurnDetail(), //转店表单
      options: new Options(), //转店表单
      rules: new Rules(),
      imgDetail: new ImgDetail(),
      rulesImg: new RulesImg(),
      addAnli: new addAnli(),
      rulesAddAnli: new rulesAddAnli(),
      // addCaseDetail:new addCaseDetail(),
      detail: {},
      dialogVisible: false, //转店弹框
      addCasePop: false, //案例提交弹框
      imgsList: [], //图片展示列表
      addImg: false, //图片上传弹框
      loading: false,
      urlList: [],
      existUrl: {},
      leadData: [
        {
          money: "￥200.00",
          num: "10"
        }
      ]
    };
  },
  mounted() {
    this.getOptions();
    this.getDetail(this.clienId);
  },
  created() {},
  methods: {
    childMethod(){
      console.log('我来了');
    },
    // 获取选项
    getOptions() {
      let that = this;
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_type_id.push(item);
          } else if (item.category_id == 2) {
            that.options.more_id.push(item);
          } else if (item.category_id == 3) {
            that.options.cause_of_formation_id.push(item);
          }
        });
      });
    },
    // 获取详情
    getDetail(clienId) {
      clientDetail({ id: clienId }).then(res => {
        this.detail = res;
        this.$emit("clientDetail", this.detail);
      });
      customerImgList({ customer_id: this.clienId }).then(res => {
        this.imgsList = res.data.list;
      });
    },
    // 搜索门店
    remoteShop(name) {
      if (name !== "") {
        this.loading = true;
        getShopList({
          shopName: name,
          page: 1,
          limit: 1000000,
          scene: "customer"
        }).then(res => {
          this.loading = false;
          this.options.in_shop_id = res.list;
        });
      } else {
        this.options.in_shop_id = [];
      }
    },
    // 点击上传照片
    upImgBtn() {
      this.addImg = true;
      this.imgDetail = new ImgDetail();
      this.existUrl = {};
    },


    // 上传图片
    getUrl(url) {
      const { absoluteUrl } = url;
      this.imgDetail.src = absoluteUrl;
    },
    // 图片
    getImgUrl({ url }) {
      // this.detail.file_path = url.map((val) => val.relativeUrl);
      let srcList = url.map((val) => val.absoluteUrl);
      this.imgDetail.src = srcList
    },
    // 点击图片
    clickImg(item, imgItem) {
      this.imgsList.forEach(val => {
        if (item.id == val.id) {
          let imgList = [];
          val.list.forEach(i => {
            imgList.push(i.src);
          });
          this.urlList = imgList;
        }
      });
    },
    // 点击删除图片
    clickDelImg(val) {
      customerDel({ id: val.id }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getDetail();
      });
    },
    // 操作
    openDialog(type, row) {
      const query = { detail: this.detail };
      switch (type) {
        case "编辑资料":
          this.$router.push({ name: "客户信息", query });
          break;
      }
    },
    // 客户转店弹框
    turnShop() {
      this.dialogVisible = true;
    },
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          switch (type) {
            case "updateTime":
              this.setTime(1);
              break;
            case "turnShop":
              this.setTime(2);
              break;
            case "turnDetail":
              this.setTurnShop();
              break;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 收到转店
    setTime(type) {
      setCustomerStatus({ customer_id: this.clienId, type: type }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.getDetail(this.clienId);
      });
    },
    // 转店提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("turnDetail", "将当前客户转为此门店");
          console.log(this.turnDetail);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 确认转店
    setTurnShop() {
      transferShop({ customer_id: this.clienId, ...this.turnDetail }).then(
        res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.dialogVisible = false;
          this.$router.push({ name: "客户列表" });
        }
      );
    },
    //上传图片
    submitImg(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.imgDetail.src)
            return this.$message({
              type: "warning",
              message: "请上传照片"
            });
          this.updatedImg();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传图片
    updatedImg() {
      customerImgAdd({
        ...this.imgDetail,
        customer_id: this.clienId,
        shop_id: this.detail.shop_id
      }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.addImg = false;
        this.getDetail();
      });
    },

    // 点击添加案例
    addCaseBtn(obj) {
      console.log(obj);
      this.addAnli.addAnliId = obj.id;
      this.addCasePop = true;
      // console.log(this.imgsList);
    },

    // 申请优案
    addCaseForm(obj) {
      console.log(this.addAnli);
      // console.log(this.imgsList);
      customerAddCase({
        ...this.addAnli,
      }).then(res=>{
        // console.log('1111');
        this.getDetail()
        this.addCasePop = false;
      });
    },

    // 查看案例
    checkCase(id){
      const query = {
        id: id,
        pageName: "案例列表",
        search: '',
        page: ''
      };

      let route = this.$router.resolve({ name: "案例详情", query });
		  window.open(route.href, '_blank');
    }

  }
};
</script>

<style lang="scss" scoped>
.conMsg {
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 34px;
      color: #666;
    }
  }
  .table {
    border-top: 1px solid #eee;
    .rowTel {
      border: 1px solid #eee;
      border-right: 0;
      border-top: 0;
      text-align: right;
      padding-right: 30px;
      height: 50px;
      line-height: 50px;
      background: #f9fafc;
      color: #878683;
      font-size: 14px;
    }
    .rowTel2 {
      border-left: 0;
    }
    .rowCon {
      border: 1px solid #eee;
      border-top: 0;
      text-align: left;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
      background: #fff;
      color: #878683;
      font-size: 14px;
    }
  }
}
.uderMsg {
  display: flex;
  width: 100%;
  .userBox {
    // height: 252px;
    width: 200px;
    background: #fafafa;
    border: 1px solid #ebeef5;
    border-right: 0;
    text-align: center;
    padding-top: 4%;
    box-sizing: border-box;
    .userImg {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      text-align: center;
      line-height: 100px;
      font-size: 16px;
      font-weight: bold;
      color: #666;
      border: 1px solid #eee;
      border-radius: 50%;
    }
    .userRank {
      background: #f04844;
      color: #fff;
      border-radius: 30px;
      width: 100px;
      height: 32px;
      margin: 20px auto 0;
      line-height: 32px;
    }
  }
  .margin-top {
    width: 100%;
  }
}
::v-deep.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  max-width: 309px;
}
.btnBox {
  width: 100%;
  text-align: center;
}
.demo-image__preview {
  // max-height: 60vh;
  // overflow-y: scroll;

  .imgListBox {
    border-bottom: 1px solid #eee;
    padding: 20px 0;
    display: flex;
    position: relative;
  }
  .imgLeft {
    max-width: 15%;
  }
  .imgRight {
    max-width: 85%;
    display: flex;
    flex-wrap: wrap;
  }
  .btnDelImg {
    position: relative;
    right: 0px;
    bottom: 10px;
  }
  .itemImg {
    position: relative;
  }
  .itemImg .el-image {
    width: 120px;
    height: 96px;
    margin-bottom: 10px;
    border-radius: 6px;
  }
  .itemImg .el-icon-circle-close:before {
    font-size: 16px;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 66;
    cursor: pointer;
    color: #999;
    background: rgba(255, 255, 255, 0.664);
    border-radius: 50%;
  }
  .upTime {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
  }
}
.notHave {
  text-align: center;
  width: 100%;
  padding: 20px 0 40px;
  font-size: 16px;
}
.el-image {
  overflow: unset;
}
</style>
