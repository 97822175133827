<template>
  <div class="content">
    <el-row class="bgW" v-if="!clienId">
      <el-col :span="22">
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="姓名/手机号">
            <el-input
              v-model="search.customer_name_or_phone"
              maxlength="30"
              class="w200"
              placeholder="请输入姓名/手机号"
              clearable
            />
          </el-form-item>
          <el-form-item label="客访日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <div v-if="clienId"></div>
        <el-button
          type="primary"
          v-if="!clienId && !userInfo.shop_id"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表','')"
        >导出数据</el-button>
        <el-button
          type="primary"
          v-if="clienId"
          class="mb10"
          size="mini"
          round
          @click="visitClient = true"
        >添加客访</el-button>
      </div>
      <!--列表-->
      <el-row v-if="!clienId">
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="客户名称" prop="customer_name" />
            <ElTableColumn label="手机号" prop="telephone" />
            <ElTableColumn label="门店名称" prop="shop_name" />
            <ElTableColumn label="员工名称" prop="return_visitor_name" />
            <ElTableColumn label="客访内容" prop="return_visit_content" />
            <ElTableColumn label="客访时间" prop="return_visit_time" />
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
      <div class="entryBox" v-if="clienId">
        <el-empty v-if="notHave" description="暂无数据" class="mr50 mt50"></el-empty>
        <div v-for="item in list" :key="item.id">
          <div class="entryRow1">
            <div class="item-circle mr30" />
            <div class="clientName">{{item.customer_name}}</div>
            <div class="entryRow2" v-if = "item.return_visitor_name > '0' && item.return_visit_time > '0'">
              <div class="ml20">{{item.return_visitor_name}}</div>
              <div class="ml20">{{item.return_visit_time}}</div>
            </div>

            <div class="entryRow2" v-else>
              <div class="ml20">下次回访</div>
              <div class="ml20">{{item.next_call_back}}</div>
              <div class="ml20 go_vist" @click="go_vist(item)" ><i class="el-icon-edit"></i>去回访</div>
              
            </div>
          </div>
          <div class="entryRow3">
            <div v-if=" item.return_visit_content > '0' ">{{item.return_visit_content}}</div>
            <div v-else></div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="添加客访记录" :visible.sync="visitClient" :close-on-click-modal="false">
      <el-form
        ref="detail"
        label-position="right"
        label-width="200px"
        :rules="rules"
        :model="detail"
        class="frame"
      >
        <el-form-item label="客户姓名:">
          <el-input v-model="consignee" class="w300" readonly></el-input>
        </el-form-item>
        <el-form-item label="客访内容" prop="return_visit_content">
          <el-input
            v-model="detail.return_visit_content"
            class="w300"
            placeholder="请填写客访内容"
            type="textarea"
          />
        </el-form-item>

        <el-form-item label="下次回访时间:">
          <el-date-picker
              v-model="detail.next_time"
              type="date"
              class="w300"
              placeholder="请选择下次回访时间"
              value-format="yyyy-MM-dd"
              :picker-options="mixTime"
            ></el-date-picker>
        </el-form-item>

        <div class="footerBtn">
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">完成，提交</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import {
  clientList,
  returnVistList,
  addVistList,
  vistExport
} from "@/api/client";
class Search {
  customer_name_or_phone = "";
  start_time = "";
  end_time = "";
}
class Detail {
  customer_id = "";
  vistId = "";
  return_visit_content = "";
  next_time = "";
}
class Rules {
  customer_id = [
    { required: true, message: "请输入客户名称", trigger: "change" }
  ];
  return_visit_content = [
    { required: true, message: "请选择客访内容", trigger: "blur" }
  ];

  // next_time = [
  //   { required: true, message: "请选择下次回访时间", trigger: "blur" }
  // ];
}
class Options {
  customer_id = [];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    consignee: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: new Page(),
      search: new Search(),
      options: new Options(),
      detail: new Detail(),
      rules: new Rules(),
      list: [],
      idList: "",
      visitClient: false,
      notHave: false,
      mixTime:{
        disabledDate(time){
          return time.getTime()<Date.now()-8.64e7;
        }
      },
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.detail.customer_id = this.clienId ? this.clienId : "";
    this.getList();
  },
  methods: {
    go_vist(item){
      this.visitClient = true;
      console.log(item);
      this.detail.vistId = item.id;
    },
    // 获取选项
    getOptions() {
      clientList({ page: 1, limit: 1000000 }).then(res => {
        this.options.customer_id = res.list;
      });
      this.detail.customer_id = this.clienId;
    },
    getList(option) {
      if (option === "search") this.page = new Page();
      returnVistList({
        ...this.page,
        ...this.search,
        customer_id: this.clienId
      }).then(res => {
        this.list = res.data.list;
        if (!this.list.length) {
          this.notHave = true;
        }
        this.page.total = res.data.dataCount;
      });
    },
    // 提交
    submitForm(formName) {
      // this.checking();
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.hint("添加该条客访记录", "add");
        } else {
          return false;
        }
      });
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "add") {
            this.confirm(type);
          } else {
            this.getExport();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      vistExport({ id: this.idList, type: 1 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 确认提交
    confirm(type) {
      addVistList({ ...this.detail }).then(res => {
        this.$message({
          type: "success",
          message: res.msg
        });
        this.visitClient = false;
        this.getList();
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 0px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 30px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
  .entryBox {
    height: 600px;
    overflow-y: scroll;
    .entryRow1 {
      display: flex;
      .item-circle {
        width: 18px;
        height: 18px;
        z-index: 2;
        border-radius: 9px;
        background-color: white;
        border: 5px solid #a5ecd7;
      }
      .clientName {
        line-height: 30px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .entryRow2 {
      line-height: 12px;
      color: #999;
      margin: 10px 0 0 20px;
      display: flex;
      font-size: 14px;
    }
    .entryRow3 {
      height:40px;
      color: #444;
      margin: 10px 0 20px;
      font-size: 15px;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
.frame {
  .el-form-item {
    margin: 0px 20px 26px 0;
  }
}
.footerBtn {
  text-align: right;
}
.go_vist{
  font-weight:bold;
  color: red;
}
</style>
