<template>
  <el-upload
    ref="upload"
    :action="uploadOptions.url"
    :headers="uploadOptions.header"
    class="upload"
    list-type="picture-card"
    :limit="limitNum"
    :file-list="imgList"
    :on-success="handleUploadSuccess"
    :on-exceed="handleExceed"
    :before-upload="beforeUpload"
  >
    <i class="el-icon-picture-outline avatar-uploader-icon">
      <div class="fz14 lh28">上传图片</div>
    </i>
    <div slot="file" slot-scope="{file}">
      <img class="el-upload-list__item-thumbnail" :src="file.absoluteUrl">
      <span class="el-upload-list__item-actions">
        <el-tooltip class="item" effect="light" content="删除图片" placement="top">
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete" />
          </span>
        </el-tooltip>
      </span>
    </div>
  </el-upload>
</template>

<script>
  // import { getToken } from '@/utils/auth';
  import { getStore } from '@/utils/store'
  class UploadOptions {
    url = process.env.VUE_APP_BASE_API + 'admin/file/save';
    header = {
      'Auth-Key': getStore('authKey') ? getStore('authKey') : '',
      'Session-Id': getStore('sessionId') ? getStore('sessionId') : '',
      'Platform': 'pc'
    }
  }
  export default {
    name: 'ImgUpload',
    props: {
      limitNum: {
        type: Number,
        default: 5
      },
      existList: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        uploadOptions: new UploadOptions(),
        imgList: [] // 图片列表
      };
    },
    watch: {
      existList(newVal) {
        if (newVal.length > 0) {
            this.imgList = this.existList.map((val, index) => {
              return {
                absoluteUrl: val.absoluteUrl,
                relativeUrl: val.relativeUrl,
                id: val.id
              };
            });
            this.$emit('setUrl', { type: 'list', url: this.imgList.map(val => val) });
        }
      }
    },
    methods: {
      // 上传
      goToUpload() {
        this.$refs.upload.$children[1].handleClick();
      },
      // 图片上传校验
      beforeUpload(file) {
        // console.log(file.type);
        if (file.type.indexOf('image') == -1) {
          this.$message.error('请上传正确的图片格式');
          return false;
        }
        const isLt10M = file.size / 1024 / 1024 < 50;
        if (!isLt10M) {
          this.$message.error('上传凭证图片大小不能超过50MB!');
          return false;
        }
      },
      // 超出回调
      handleExceed() {
        this.$message.error(`最多可上传${this.limitNum}张图片！`);
      },
      // 上传图片成功回调
      handleUploadSuccess(res, file, fileList) {
        // console.log(res, file, fileList);
        if (res) {
          fileList.forEach(item => {
            const { response } = item;
            item.absoluteUrl = response ? response.data.file_path : item.absoluteUrl;
            item.relativeUrl = response ? response.data.save_name : item.relativeUrl;
            item.id = response ? response.data.id : item.id;
          });
          this.imgList = fileList;
          this.$emit('setUrl', { type: 'list', url: this.imgList.map(val => val) });
        }
      },
      // 删除回调
      handleRemove(file) {
        this.imgList.splice(this.imgList.findIndex(val => val.uid === file.uid), 1);
        this.$emit('setUrl', { type: 'list', url: this.imgList.map(val => val) });
      },
      // 清空列表
      handleClear() {
        this.imgList = [];
      }
    }
  };
</script>

<style scoped lang="scss">
  .upload {
    ::v-deep.el-upload--picture-card,::v-deep.el-upload-list__item {
      width: 100px!important;
      height: 100px!important;
      line-height: 100px!important;
    }
  }
  .avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    line-height: 28px;
    text-align: center;
    vertical-align: middle;
  }
</style>
