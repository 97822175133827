<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="客户姓名" v-if="!clienId">
            <el-input v-model="search.customer_name" clearable class="w100" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="手机号" v-if="!clienId">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id && !clienId">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteShop"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="回访日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="回访类型">
            <el-select v-model="search.list_type" clearable class="w120">
              <el-option
                v-for="item in options.list_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          v-if="!userInfo.shop_id"
          @click="hint('导出选中列表','export')"
        >导出数据</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="回访日期" width="150" prop="return_visit_time" />
            <ElTableColumn label="顾客姓名" prop="customer_name" />
            <ElTableColumn label="手机号" prop="telephone" />
            <ElTableColumn label="归属市场" prop="market_name" />

            <ElTableColumn label="归属门店" prop="shop_name" />
            <ElTableColumn label="责任护理师" prop="nurse_name" />
            <ElTableColumn label="回访结果">
              <template slot-scope="{ row }">
                <span v-if="row.result == 0">暂无评价</span>
                <span v-if="row.result == 1">满意</span>
                <span v-if="row.result == 2">一般</span>
                <span v-if="row.result == 3">不满意</span>
                <span v-if="row.result == 4">投诉 ({{row.result_name}})</span>
                <span v-if="row.result == 5">流失</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="问题分类" prop="setting_name" />
            <ElTableColumn label="综合评分" prop="total" />
            <ElTableColumn label="是否预警">
              <template slot-scope="{ row }">
                <span>{{row.early_warning==0?'否':'是'}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="是否追责">
              <template slot-scope="{ row }">
                <span>{{row.accountability==0?'否':'是'}}</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span v-if="clienId" class="theme" @click="tapBtn('客户查看',row.id)">查 看</span>
                <span v-else class="theme" @click="tapBtn('查看',row.id)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { clientList, ssettingList } from "@/api/client";
import { getShopList } from "@/api/shop";
import { staffList } from "@/api/staff";
import { returnReturnVistT, exportReturn } from "@/api/againVisit";

class Search {
  customer_name = "";
  telephone = "";
  shop_id = "";
  start_time = "";
  end_time = "";
  list_type = 1;
}
class Options {
  shop_id = [];
  customer_id = [];
  setting_id = [];
  nurse_id = [];
  result_id = [];
  list_type = [
    { id: 1, name: "全部" },
    { id: 2, name: "追责" },
    { id: 3, name: "预警" }
  ];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}

export default {
  name: "ClientList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      list: [],
      visitClient: false,
      idList: ""
    };
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
    this.getOptions();
  },
  methods: {
    // 回访列表
    getList(option) {
      if (option === "search") this.page = new Page();
      returnReturnVistT({
        ...this.search,
        ...this.page,
        customer_id: this.clienId
      }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },
    // 获取数据列表
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 9) {
            that.options.setting_id.push(item);
          }
          if (item.category_id == 11) {
            that.options.result_id.push(item);
          }
        });
      });

      staffList({ page: 1, limit: 1000000 }).then(res => {
        this.options.nurse_id = res.list;
      });
    },
    // 搜索门店
    remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 点击详情
    tapBtn(type, id) {
      switch (type) {
        case "客户查看":
          const queryT = {
            id: id,
            types: "ninth",
            clienId: this.clienId,
            detail: this.detail
          };
          this.$router.push({ name: "客保详情", query: queryT });
          break;
        case "查看":
          this.$router.push("./visitDetail?id=" + id);
          break;
      }
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "export") {
            this.getExport();
          } else {
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      exportReturn({ id: this.idList }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
.visit {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .el-form-item {
    margin: 0px 20px 30px 0;
  }
}
.center {
  width: 100% !important;
}
.footerBtn {
  width: 100%;
  text-align: right;
}
</style>
