<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="成交日期">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              class="w220"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="客户名称" v-if="!clienId">
            <el-input
              v-model="search.consignee"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="门店名称" v-if="!clienId">
            <el-input
              v-model="search.shop_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="销售金额">
            <el-input
              v-model="search.low_total"
              maxlength="30"
              class="w84"
              placeholder="最小值"
              clearable
            />
            <span></span>
            <el-input
              v-model="search.large_total"
              maxlength="30"
              class="w84"
              placeholder="最大值"
              clearable
            />
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="search.business_type" placeholder="请选业务类型" class="w120" clearable>
              <el-option
                v-for="item in options.business_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select v-model="search.order_type" placeholder="请选订单类型" class="w120" clearable>
              <el-option
                v-for="item in options.order_type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="显示作废订单">
            <el-select v-model="search.display_cannel" placeholder="是否显示作废订单" class="w120" clearable>
              <el-option
                v-for="item in options.display_cannel"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
           v-if="!userInfo.shop_id" 
          @click="tapDetail('export')"
        >导出数据</el-button>
        <div  v-if="userInfo.shop_id" ></div>
        <el-button v-if="!clienId" type="primary" class="mb10" size="mini" @click="tapDetail('lock')">锁定订单</el-button>
        <span v-if="is_show_add">
          <el-button
          v-if="clienId"
          type="primary"
          class="mb10"
          round
          size="mini"
          @click="tapDetail('add')"
        >添加销售出库单</el-button>
        </span>
      </div>
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn  v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="成交日期" prop="deal_time" />
            <ElTableColumn label="客户名称" prop="consignee" />
            <ElTableColumn label="客户K3代码" prop="king_no" />
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="护理师" prop="sale_name" />
            <ElTableColumn label="标准金额" prop="goods_price" />
            <ElTableColumn label="销售金额" prop="order_amount" />
            <ElTableColumn label="业务类型" prop="business_type" />
            <ElTableColumn label="订单类型" prop="order_type" />
            <ElTableColumn label="订单状态" prop="status_msg" />
            <ElTableColumn label="是否锁定" >
              <template slot-scope="{ row }">
                <span >{{row.is_lock == 0?'未锁定':'已锁定'}}</span>
                
              </template>
            </ElTableColumn>
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" v-if="clienId" @click="tapDetail('销售记录详情',row.id)">查看</span>
                <span class="theme" v-else @click="tapDetail('销售出库单详情',row.id)">查看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <div class="reveal" v-if="sum_amount != 'noShow'">
            <span class="mr20">
              销售金额合计：
              <span class="peril">{{sum_amount}}</span>
            </span>
          </div>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from "@/components/Pagination";
import { orderList, exportOrder, lockOrder } from "@/api/order";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  consignee = "";
  shop_name = "";
  low_total = "";
  large_total = "";
  business_type = "";
  order_type = "";
  display_cannel = 0;
}
class Options {
  business_type = [
    { id: 1, name: "疤痕" },
    { id: 2, name: "项目" },
	{ id: 3, name: "青春痘" },
	{ id: 4, name: "护肤品" }
  ];
  order_type = [
    { id: 1, name: "新单" },
    { id: 2, name: "补单" }
  ];
  display_cannel = [
    { id: 0, name: "不显示" },
    { id: 1, name: "显示" }
  ];
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  props: {
    clienId: {
      type: String,
      default: ""
    },
    detail: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: "",
      value1: [],
      sum_amount: "noShow",
      is_show_add:true
    };
  },
computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option == "search") this.page = new Page();
      orderList({
        ...this.search,
        ...this.page,
        uid: this.clienId ? this.clienId : "",
        status: "-1",
      }).then(res => {
        this.is_show_add = res.is_show_add
        this.list = res.list;
        this.page.total = res.dataCount;
        if (this.search.end_time) {
          this.sum_amount = res.sum_amount;
        } else {
          this.sum_amount = "noShow";
        }
      });
    },
    // 日期搜索
    searchTime(val) {
      if (val) {
        this.search.start_time = val[0];
        this.search.end_time = val[1];
      } else {
        this.search.start_time = "";
        this.search.end_time = "";
      }
    },
    // 点击按钮
    tapDetail(type, id) {
      switch (type) {
        case "销售出库单详情":
          this.$router.push("./orderDetail?id=" + id + "&type=order");
          break;
        case "销售记录详情":
          const queryD = { id: id, detail: this.detail, type: "thirdly" };
          this.$router.push({ name: "销售出库单详情", query: queryD });
          break;
        case "add":
          const query = { clienId: this.clienId, detail: this.detail };
          this.$router.push({ name: "创建订单", query });
          break;
        case "export":
          this.hint("导出选中列表", "export");
          break;
        case "lock":
          if (this.search.end_time) {
            this.hint("锁定选中条件的订单", "lock");
          } else {
            this.$message({
              message: "请选择锁定日期",
              type: "warning"
            });
          }
          break;
      }
    },
    // 提示
    hint(text, type) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "export") {
            this.getExport();
          } else {
            this.getLockOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //锁单
    getLockOrder() {
      lockOrder({ ...this.search }).then(res => {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.getList();
      });
    },
    // 导出
    getExport() {
      exportOrder({ id: this.idList }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  //   padding: 10px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 10px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
.reveal {
  text-align: right;
  padding: 20px 0;
  .peril {
    font-weight: bold;
  }
}
.btnBox {
  display: flex;
  justify-content: space-between;
}
</style>
