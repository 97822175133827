import axios from '@/utils/axios'

// 案例列表
  export const caseList = data =>
  axios.post('/crm/personCase/index', data)
    .then(res => res)

  // 添加案例
  export const addCase = data =>
  axios.post('/crm/personCase/addCase', data)
    .then(res => res)

  // 添加案例
  export const caseDetail = data =>
  axios.post('/crm/personCase/caseDate', data)
    .then(res => res)

  // 添加案例记录
  export const addCaseLog = data =>
  axios.post('/crm/personCase/addCaseLog', data)
    .then(res => res)

  // 添加案例记录
  export const saveCase = data =>
  axios.post('/crm/personCase/saveCase', data)
    .then(res => res)

  // 电话评级
  export const psCase = data =>
  axios.post('/crm/personCase/psCase', data)
    .then(res => res)
    
  // 申请评级
  export const psTj = data =>
  axios.post('/crm/personCase/psTj', data)
    .then(res => res)

  // 案例技术评级
  export const jspsCase = data =>
  axios.post('/crm/personCase/jspsCase', data)
    .then(res => res)

  // 加入案例库
  export const addpsCase = data =>
  axios.post('/crm/personCase/addpsCase', data)
    .then(res => res)

// 案例列表导出
export const caseExport = data =>
axios.post('/crm/PersonCase/index_export', data)
  .then(res => res)

// 案例删除
export const delCase = data =>
axios.post('/crm/personCase/delCase', data)
  .then(res => res)
  
// 案例评选记录
export const caseHistoryLog = data =>
axios.post('/crm/personCase/caseHistoryLog', data)
  .then(res => res)