<template>
  <div>
    <div class="return" v-if="pageName" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    
    <div class="switch" >
      <el-switch
        v-if="activeName == 'first'"
        v-model="value1"
        active-text="门诊信息"
        inactive-text="门店信息"
        @change="changeUser">
      </el-switch>
    </div>
    
    <el-tabs type="border-card"   v-model="activeName">
      <el-tab-pane label="用户详情" name="first">
        <detailMsg v-if="activeName=='first' && id " :clienId="id + ''" @clientDetail="clientDetails" ref="detailMsg"/>
      </el-tab-pane>
      <el-tab-pane label="收款记录" name="second" v-if="oldId == id">
         <gathering v-if="activeName=='second' && id " :clienId="id + ''" :detail ="cliDel"/>
      </el-tab-pane>
      <el-tab-pane label="销售记录" name="thirdly" v-if="oldId == id">
         <sellRecord v-if="activeName=='thirdly' && id " :clienId="id + ''"  :detail="cliDel"/>
      </el-tab-pane>
       <el-tab-pane label="退款列表" name="fourth" v-if="oldId == id">
         <refundList v-if="activeName=='fourth' && id " :clienId="id + ''" :detail ="cliDel"/>
      </el-tab-pane>
       <el-tab-pane label="退货列表" name="fifth" v-if="oldId == id">
         <refundGoods v-if="activeName=='fifth' && id " :clienId="id + ''" :detail ="cliDel"/>
      </el-tab-pane>
      <el-tab-pane label="服务卡项" name="sixth">
        <serveRecord v-if="activeName=='sixth' && id " :clienId="id + ''" :detail="cliDel"/>
      </el-tab-pane>
      <el-tab-pane label="优惠券" name="seventh" v-if="oldId == id">
        <cardList v-if="activeName=='seventh' && id " :clienId="id + ''" :detail="cliDel"/>
      </el-tab-pane>
      <el-tab-pane label="客访记录" name="eighth" v-if="oldId == id">
        <answerVisit  v-if="activeName=='eighth' && id " :clienId="id + ''" :consignee="cliDel.customer_name"/>
      </el-tab-pane>
      <el-tab-pane label="客保记录" name="ninth" v-if="oldId == id">
        <newList  v-if="activeName=='ninth' && id " :detail="cliDel" :clienId="id + ''" />
      </el-tab-pane>
      <el-tab-pane label="案例列表" name="tenth">
        <caseList  v-if="activeName=='tenth' && id " :detail="cliDel" :clienId="id + ''" />
      </el-tab-pane>
      
      <el-tab-pane label="治疗方案" name="twelfth" v-if="isMedical">
        <healPlan   v-if="activeName=='twelfth' && id " :clienId="id + ''" :consignee="cliDel.customer_name"/>
      </el-tab-pane>
      <el-tab-pane label="修复方案" name="eleventh" v-else>
        <repairPlan   v-if="activeName=='eleventh' && id " :clienId="id + ''" :consignee="cliDel.customer_name"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import detailMsg from './components/detailMsg.vue'
import serveRecord from '../serve/serveList.vue'
import cardList from './components/cardList.vue'
import gathering from '../order/proceedsList.vue'
import sellRecord from '../order/orderList.vue'
import refundList from '../order/refundList.vue'
import refundGoods from '../order/refundGoods.vue'
import answerVisit from './answerVisit.vue'
import newList from '../againVisit/newList.vue'
import caseList from '../case/caseList.vue'
import repairPlan from './repairPlan.vue'
import healPlan from './healPlan.vue'
import { clientDetail,readCustomerId } from "@/api/client";
import { ref } from 'vue';
import { mapGetters } from "vuex";

export default {
  
  name: 'ClientDetail',
  components: {
    detailMsg,
    serveRecord,
    cardList,
    sellRecord,
    gathering,
    refundList,
    refundGoods,
    answerVisit,
    newList,
    caseList,
    repairPlan,
    healPlan
  },
  
  data () {
    return {
      activeName:'first',
      customer_name:'',
      oldId:"",
      id:"",
      cliDel:'',
      pageName:'',
      value1: false,
      ids:[],
      loading:true,
      medicalShop:[73782774881835,73782774991693,73782775085647,73782775131388,73782775206631,73782775392774,73782775377728],
      isMedical:false,
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted () {
    this.oldId = this.$route.query.id.toString()
    this.id = this.$route.query.id.toString()
    this.customer_name = this.$route.query.customer_name
    this.activeName = this.$route.query.activeName;
    this.cliDel = this.$route.query.detail;
    this.pageName = this.$route.query.pageName;
    this.getDetail()
    this.readCustomerId()
  },
  methods: {
    // 获取详情
    getDetail() {
      clientDetail({ id: this.id }).then(res => {
        this.cliDel = res

        let medicalShop = this.medicalShop;

        this.isMedical  = medicalShop.includes(res.shop_id);
        this.value1 = medicalShop.includes(res.shop_id);
      });
    },
    // 获取同手机号账户
    readCustomerId(){
      readCustomerId({ id: this.id }).then(res => {
        // console.log(res);
        this.ids = res;
      });
    },
    // 参数
      clientDetails(del){
        this.cliDel = del
      },
      // 返回
    back() {
        const query = { search: this.$route.query.search,page:this.$route.query.page}
        this.$router.push({ name: this.$route.query.pageName,query});
      
    },
    changeUser(e){
      let ids = this.ids;
      console.log(e);
      // let clienId = '';
      
      
      if(e){
        if(ids[1] == undefined){
          this.$message.error('暂无客户信息');
          return false;
        }
        this.id = ids[1];
      }
      if(!e){
        if(ids[2] == undefined){
          this.$message.error('暂无客户信息.');
          return false;
        }
        this.id = ids[2];
      }
      this.getDetail();
      this.$refs.detailMsg.getDetail(this.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
.switch{
  width: 100%;
  height: 40px;
  z-index: 999;
}
</style>
